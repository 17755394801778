.car-class {
  td {
    background: linear-gradient(rgba(0, 0, 0, 0.75), #005999c2), url("./../../assets/images/car.jpg");
    background-size: 100% 100% !important;
  }
}
.truck-class {
  td {
    background: linear-gradient(rgba(0, 0, 0, 0.75), #005999c2), url("./../../assets/images/truck.png");
    background-size: 100% 100% !important;
  }
}
.bus-class {
  td {
    background: linear-gradient(rgba(0, 0, 0, 0.75), #005999c2), url("./../../assets/images/bus.png");
    background-size: 100% 100% !important;
  }
}
.van-class {
  td {
    background: linear-gradient(rgba(0, 0, 0, 0.75), #005999c2), url("./../../assets/images/van.png");
    background-size: 100% 100% !important;
  }
}
.default-class {
  td {
    background: linear-gradient(rgba(0, 0, 0, 0.75), #005999c2), url("./../../assets/images/default.png");
    background-size: 100% 100% !important;
  }
}
.disable {
  td {
    display: none;
  }
}
