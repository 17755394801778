@import "./../../../styles/_variables";

.alert-form {
  padding: 0 130px 0 25px;
  margin: 0;

  .align-left {
    margin-left: 25px;
  }
  @media screen and (max-width: 1024px) {
    padding: 0;
  }
  input[type="radio"] {
    display: none;
  }

  input[type="radio"] + label.type-radio {
    display: inline-block;
    cursor: pointer;
    outline: 0;
    background-image: url("./../../../assets/icons/Radio_button-unchecked.svg");
    background-repeat: no-repeat;
    background-position: 0 0;
    text-indent: 19px;
  }

  input[type="radio"]:checked + label.type-radio {
    background-image: url("./../../../assets/icons/Radio_button-checked.svg");
  }

  .custom-datepicker {
    .rdtPicker {
      background-color: $blue-green;
      border: none;
      color: $font-color;

      .rdtOld,
      .rdtNew {
        color: $skobeloff;
      }

      .rdtToday::before {
        display: none;
      }

      .rdtActive {
        background-color: rgb(1, 80, 94);
      }
    }
  }

  .custom-multi-select {
    .css-1hwfws3:focus {
      outline: none;
    }
    .css-hjm3sl:hover,
    .css-hjm3sl:focus {
      outline: none;
      border-color: transparent !important;
    }

    // dropdown selector
    .css-15k3avv {
      background-color: $primary;
    }
    // search input
    .css-1g6gooi {
      color: $font-color;
    }
    .css-1492t68 {
      display: contents;
      padding-top: 2%;
    }
  }
  .delete-periodic {
    margin-top: 34px;
  }
  .actions {
    width: 110px;
    margin-right: 20px;
    margin-top: 3rem;
    @media screen and (max-width: 600px) {
      margin-top: 1rem;
      width: 100%;
    }
  }

  .css-1lxd980 {
    box-shadow: none !important;
    &:hover {
      border-color: none;
    }
  }
  .css-1r36co3 {
    box-shadow: none !important;
  }
  .css-1quhzxg {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding-right: 6px;
  }
  .css-go1snr {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .custom-datepicker {
    &:before {
      content: "";
      width: 15px;
      height: 15px;
      background: url("../../../assets/icons/Shape_2.svg") no-repeat;
      background-position: center center;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;
    }
    input {
      background: url("../../../assets/icons/Calendar.svg") no-repeat;
      background-position: left 10px center;
      background-color: #181818;
      padding-left: 40px;
      padding-right: 33px;
    }
  }
  textarea {
    padding: 10px;
  }
  .periodic {
    width: 100%;
  }
  .form-group {
    p {
      margin-bottom: 0;
    }
  }
  @media (min-width: 768px) {
    .language-switcher-left {
      position: relative;
      padding-right: 8px;
      button {
        font-size: 0;
        width: 29px;
        height: 27px;
        border-radius: 50%;
        border: none;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin-top: 10px;
        z-index: 1;
        right: -10px;
        cursor: pointer;
        background: url("../../../assets/icons/Shape_5.svg") no-repeat;
        background-position: center center;
        background-color: rgba(2, 133, 157, 1);
        background-size: 17px 19px
      }
    }
    .language-switcher-right {
      padding-left: 2px;
    }
  }
  .rdtPicker .rdtTimeToggle {
    font-size: 14px;
    padding: 10px 0;
  }
  .start-periodical,
  .start-periodical-maintenance {
    padding: 0 12.5px 0 25px;
  }
  .end-periodical,
  .end-periodical-maintenance {
    padding: 0 25px 0 25px;
  }
  @media (max-width: 1389px) {
    .periodical-maintenance {
      width: 100%;
      flex: auto;
      max-width: 100%;
    }
    .start-periodical-maintenance,
    .end-periodical-maintenance {
      width: 50%;
      flex: auto;
      max-width: 100%;
      padding: 0 25px 0 25px;
    }
    
  }
  @media (max-width: 1000px) {
    .reason {
      width: 100%;
      flex: auto;
      max-width: 100%;
    }
    .start-periodical,
    .end-periodical {
      width: 50%;
      flex: auto;
      max-width: 100%;
      padding: 0 25px 0 25px;
    }
  }
  @media (max-width: 767px) {
    .form-group {
      width: 100%;
      flex: auto;
      max-width: 100%;
    }
    .align-left {
      margin-left: 0;
    }
    .language-switcher-left {
      button {
        width: 100%;
        background-color: rgba(0, 0, 0, 1);
        border: 1px solid rgba(2, 133, 157, 1);
        border-radius: 3px;
        box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.71);
        padding: 10px 0;
        margin-top: 12px;
        font-family: "Poppins";
        font-size: 12px;
        color: rgba(2, 133, 157, 1);
        cursor: pointer;
        &:before {
          content: "";
          display: inline-block;
          background: url("../../../assets/icons/Shape_3.svg") no-repeat;
          height: 15px;
          width: 15px;
          margin-right: 10px;
          vertical-align: middle;
        }
      }
    }
  }

  .rdtPicker thead tr:first-child th:hover {
    background: rgba(24, 24, 24, 1);
  }
  .rdtPicker {
    padding: 0;
  }
  .rdtPicker th.rdtSwitch {
    background: #181818;
    border-bottom: none;
  }
  .rdtPrev span,
  .rdtNext span {
    background: #181818;
  }
  .rdtPicker th.rdtNext,
  .rdtPicker th.rdtPrev {
    background: #181818;
    border: none;
  }
  .rdtPicker {
    table {
      thead {
        tr {
          &:nth-child(2) {
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
          }
        }
      }
    }
  }

  .custom-datepicker input {
    box-shadow: none;
  }

  .rdtPicker td.rdtDay:hover,
  .rdtPicker td.rdtHour:hover,
  .rdtPicker td.rdtMinute:hover,
  .rdtPicker td.rdtSecond:hover,
  .rdtPicker .rdtTimeToggle:hover {
    background: #02859d;
  }
  .rdtPicker tfoot {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }
  .css-d8oujb {
    display: none;
  }
  .css-1ep9fjw {
    margin-right: 9px;
    cursor: pointer;
    &:first-child {
      background: url("../../../assets/icons/close.svg") no-repeat;
      background-position: center;
      padding-right: 34px;
    }
    &:last-child {
      background: url("../../../assets/icons/Sort_desc.svg") no-repeat;
      background-position: center;
      background-position: center center;
      position: absolute;
      right: 0px;
      top: 50%;
      transform: translateY(-50%);
    }
    svg {
      display: none;
    }
  }
  .css .css-19bqh2r,
  .css-1uq0kb5 {
    margin-right: 9px;
    &:last-child {
      background: url("../../../assets/icons/Sort_asc.svg") no-repeat;
      background-position: center;
    }
    &:first-child {
      background: url("../../../assets/icons/close.svg") no-repeat;
      background-position: center;
    }
    svg {
      display: none;
    }
  }
  .css-xp4uvy {
    color: $font-color !important;
  }
  .dates-periodic {
    background: rgba(24, 24, 24, 1) !important;
    border: none;
    outline: none;
    font-size: 12px;
    background-position: left 10px center;
    background-color: #181818;
  }
  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"] + label {
    background: url("./../../../assets/icons/Checkbox_unchecked.svg") no-repeat;
    background-size: 100%;
    height: 14px;
    width: 14px;
    display: inline-block;
    padding: 0 0 0 0;
  }

  input[type="checkbox"]:checked + label {
    background: url("./../../../assets/icons/Checkbox.svg") no-repeat;
    background-size: 100%;
    height: 14px;
    width: 14px;
    display: inline-block;
    padding: 0 0 0 0;
  }
  .periodic-enable {
    label {
      margin-bottom: -3px;
      cursor: pointer;
    }
    .ml-5 {
      margin-left: 5px !important;
    }
  }

  .col-wd {
    width: 24%;
    flex: none;
  }
  
}

  