@import "./../../styles/variables";

.list-container {
  padding-right: 15px;

  .heading {
    flex-grow: 1;
    padding-top: 7px;
  }
  .right-align {
    margin-left: auto;
  }
  .count {
    font-size: 12px;
    .planned {
      padding-left: 8px;
    }
  }
  .react-bootstrap-table {
    overflow: auto !important;
    width: auto;

    .table {
      min-width: 658px;
      table-layout: fixed;
      td {
        text-transform: capitalize;
        font-size: 13px;
        color: $white-70;
        background-color:#a6cad8;
      }
      th:first-child {
        width: 2.4%;
      }
      th:nth-child(2) {
        width: 3.5%
      }
      th:nth-child(3),:nth-child(4),:nth-child(5) {
        width: 8%;
      }
      th:nth-child(7) {
        width: 15%;
      }
      th:nth-child(6) {
        width: 6.8%;
      }
      th:nth-child(8) {
        width: 146px;
        word-break: break-all;
      }
      th:nth-child(9) {
        width: 38%;
      }
      th:nth-child(10) {
        width: 70px;
      }
      tr.upcoming td{
        color: $font-color !important;
        background-color: $rich-black;
        font-size: 10px;
      }
      tr.expired td {
        background-color:#707070;
        font-size: 10px;

      }
      tr.noDataEntry td:nth-child(10) {
        box-shadow: none;
      }
      tr:first-child {
        padding-top:10px;
      }
     
    }
  }
}
hr {
  margin-top: 0px !important;
}
.main-container {
  // background-color: black !important;

}