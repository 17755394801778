@import "./../../styles/variables";

.function-category {
  .add-new-item {
    .add-new-item-form {
      .form-group {
        &:nth-child(1) {
          flex: 1;
          max-width: 100%;
        }

        &:nth-child(2) {
          flex: 1;
          max-width: 100%;
        }

        &:nth-child(3) {
          max-width: 250px;
          min-width: 250px;

          button {
            width: 100%;
          }
        }
      }
    }
  }

  .custom-row-class {
    td:first-child {
      border-radius: 4px 0 0 4px;
    }

    &.upcoming td:first-child {
      border-left: solid 4px $blue-green;
    }
    &.disable-delete .delete {
      display: none;
    }
  }
}
