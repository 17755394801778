@import "./../../../styles/variables";

.communication-possible-disable-modal {
  .modal-content {
    background-color: $primary;
  }
  .modal-header{
    border: none;
    .modal-title{
      font-size: 15px;
    }
    button{
      display: none;
    }
  }
  .modal-body{
    font-size: 13px;
  }
  .modal-footer{
    border: none;
    button{
    font-size: 13px;
    }
  }
}