/*Set font family base*/
$font-family-base: 'Poppins', sans-serif;

/* Colors */
$ghost-white: #f9fafb;
$white-smoke: #f5f6fa;
$platinum: #dbe2e5;
$davy-gray:#5c5c5c;
$pomegranate: #f44336;
$dark-jungle-green: rgba(24, 24, 24, 1);
$dark-jungle-green-2: rgba(37, 37, 37, 1);
$black: rgba(0, 0, 0, 1);
$black-8: rgba(0, 0, 0, 0.8);
$black-63: rgba(0, 0, 0, 0.63);
$white: rgba(255, 255, 255, 1);
$white-70: rgba(255, 255, 255, 0.70);
$blue-green: rgba(2, 133, 157, 1);
$teal-blue: rgba(27, 121, 143, 1);
$skobeloff: rgba(25, 96, 113, 1);
$onyx: rgba(15, 15, 15, 1);
$smoky-black:rgba(10, 10, 10, 1);
$rich-black :rgba(0, 51, 64, 1);
$upsdell-red:rgba(159, 25, 35, 1);

/* Color Schemes */
$primary: $dark-jungle-green;
$secondary: $black;
$secondary-dev: $davy-gray;
$error-text: $pomegranate;
$font-color: $white;
$btn-primary: $blue-green;
$btn-secondary: $dark-jungle-green;
$box-shadow: $black-63;
$placeholder: rgb(127, 127, 127);
