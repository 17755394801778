@import './../../../styles/variables';
.more-action {
  background-color: transparent;
  top: 5px !important;
  left: 6px !important;
  .popover-body {
    display: flex;
    flex-direction: column;
    padding: 0;
    min-width: 140px;
    .action{
      font-size: 12px;
      height: 47px;
      border-radius: 0;
      text-align: left;
      &:first-child{
        border-radius: 2px 2px 0 0;
        border-bottom: solid 1px $teal-blue;
      }
      &:last-child{
        border-radius: 0 0 2px 2px;
      }
      &:only-child {
        border-radius: 2px;
      }
      &:hover{
        background-color: $teal-blue !important;
      }
    } 
  }
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
}

.popup-more,
.popup-more button {
  background-color: #707070 !important;
}

.upcoming .popup-more,
.upcoming .popup-more button {
  background-color: $rich-black !important;
}