@import "_normalize";
@import "../../node_modules/bootstrap//dist/css//bootstrap.min";
@import "../../node_modules/react-toastify/dist/ReactToastify.css";
@import "../../node_modules/axios-progress-bar/dist/nprogress.css";
@import "generic";
@import "_react-bootstrap-table";
@import "_calendar";
@import "_add-new-item-form";

/** To override progress bar */
#nprogress .bar {
  height: 3px !important;
  background-color: rgba(2, 133, 157, 1) !important;
}

/* To override red cross in input when error true */
.is-invalid {
  background-image: none !important;
}