@import "./../../styles/variables";

.create-alert {
  .page-header {
    padding-right: 160px;
    flex-direction: row;

    @media screen and (max-width: 1024px) {
      padding-right: 0;
    }
    @media screen and (max-width: 425px) {
      flex-direction: column;
      > div {
        width: 100%;
      }
      > :last-child {
        margin: 0 !important;
      }
    }
  }

  .actions {
    width: 110px;
    margin-right: 20px;
  }
}
