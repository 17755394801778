.rdtPicker {
  font-size: 12px;
  color: rgba(255, 255, 255, 1);
}
.rdtPicker {
  .rdtActive {
    border-radius: 3px;
  }
}

.rdtDisabled:hover{
  &:after {
    z-index: 50;
    color: white;
    background-color: black;
    content: '  '+ 'blocked' + '  ';
    white-space: pre;
    position: absolute;
    border-radius: 2px;
  }
}