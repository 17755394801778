@import "../../styles/variables";
.main-container {
  padding-top: 60px;
  &.login-page {
    background-color: white;
    height: 100vh;
  }
}
.header {
  background-color: $dark-jungle-green-2;
  position: fixed;
  right: 0;
  left: 0;
  z-index: 10;
  top: 0;

  .top-bar {
    padding: 10px 15px;
    flex-direction: row;
    background-color: $dark-jungle-green-2;
    height: 50px;
    .logo {
      width: 109px;
      height: 31px;
    }
  }
  .center-align {
    margin-left: auto;
    margin-top: 0.7rem;
  }
  .logo-name {
    text-transform: uppercase;
    font-size: 12px;
  }
  .right {
    margin-left: auto;
    
      .btn-group {
       
        .dropdown-toggle {
          background-color: $dark-jungle-green-2 !important;
          border: none;
          border-radius: 0;
          border-right: solid 1px grey;
          border-left: solid 1px grey;
          text-transform: uppercase;
          
          &:focus {
            box-shadow: none;
            background-color: $dark-jungle-green-2 !important;
          }
          &:active {
            background-color: $dark-jungle-green-2 !important;
          }
        }

        .dropdown-menu {
          background-color: $blue-green;
          min-width: 140px;
          transform: translate3d(
            -46px,
            49px,
            0
          ) !important; // to override inline generated css
          .dropdown-item {
            color: $font-color;
            font-size: 12px;
            text-transform: uppercase;
            background-color: $blue-green;
            cursor: pointer;
            &:hover {
              background-color: $blue-green;
            }
          }

          .dropdown-divider {
            border-top: 1px solid $teal-blue;
          }
        }
      }
      .user-name {
        .btn-group {
          .dropdown-toggle {
            border: none;
            padding: 0;
          }
          .dropdown-menu .dropdown-item {
            text-align: center;
          }
        }
      }

    .greetings {
      .text {
        color: $blue-green;
      }
    }
    .language {
      margin-top: 5px;
    }
  } 
  .header-toggle {
    margin-top: -8px;
  }
}

@media screen and (max-width: 848px) {
  .header-toggle {
    display: none;
  }
}