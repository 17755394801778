.page-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
  button.plus-btn {
    height: 38px;
    width: 38px;
    background-color: rgba(2, 133, 157, 1);
    border: none;
    cursor: pointer;
    border-radius: 3px;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.71);
  }

@media (min-width: 768px) {
  .mobile-only {
    display: none !important;
  }
    button.plus-btn {
      display: none;
    }
}
.add-new-item {
  @media (max-width: 767px) {
    background: black;
    position: fixed;
    z-index: 11;
    top: 0;
    height: 100%;
    display: none;
    width: 100%;
    &.active {
      display: block;
    }
  }
}
@media (max-width: 767px) {
  .popup-header {
    padding: 35px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgba(24, 24, 24, 1);
    margin-bottom: 20px;
    button {
      padding: 0;
      background: transparent;
      border: none;
      cursor: pointer;
    }
  }
}