@import 'variables';


body {
  font-family: $font-family-base;
  background-color: $secondary;
  color: $font-color;
  display: block;
  justify-content: center;
  width: 100%;
}

.btn {
  color: $font-color;
  border: none;
  outline: none;

  &:focus:active {
    box-shadow: none !important;
  }
}

.font-blue-green {
  color: $blue-green;
}

.btn-primary {
  background-color: $btn-primary;

  &:hover,
  &:focus,
  &:active,
  &:disabled {
    border: none;
    background-color: $btn-primary !important;
    outline: none;
    box-shadow: none;
  }

  &:focus:active {
    box-shadow: none;
  }
}

.btn-secondary {
  background-color: $btn-secondary;

  &:hover,
  &:focus,
  &:active {
    border: none;
    background-color: $btn-secondary !important;
    outline: none;
    box-shadow: none;
  }
}

.btn-cancel {
  background-color: $smoky-black;
}

.w-100 {
  width: 100%;
}

.fs-10 {
  font-size: 10px;
}

.fs-11 {
  font-size: 11px;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 16px;
}

.fs-25 {
  font-size: 25px;
}

.pointer {
  cursor: pointer;
}

.hidden {
  visibility: hidden;
}

.pr-16 {
  padding-right: 16px;
}

.h-60 {
  height: 60px;
}

hr {
  border: .5px solid rgba(51, 51, 51, 1);
  width: 100%;
}

.flag {
  height: 26px;
  width: 26px;
  border-radius: 50%;
}

input[type='text'],
textarea {
  background-color: $dark-jungle-green;
  border: none;
  color: $font-color;
  font-size: 13px;
  box-shadow: none;

  &:focus {
    background-color: $dark-jungle-green;
    color: $font-color;
    border-color: $blue-green;
    box-shadow: none;
  }
}

textarea {
  color: $font-color;
  padding: .375rem .75rem;
  border-radius: .25rem;

  &:focus {
    outline: none;
  }
}

.errors-text {
  color: $error-text;
}

sup {
  font-size: 15px;
  top: 0;
  color: $blue-green;
}

span[disabled] {
  pointer-events: none;
}

.page-padding {
  padding-right: 15px;
}

.add-item-btn {
  height: calc(2.25rem + 2px);
}



.lds-dual-ring {
  display: inline-block;
  &:after {
    content: " ";
    display: block;
    width: 15px;
    height: 15px;
    margin-left: 12px;
    border-radius: 50%;
    border: 2px solid $blue-green;
    border-color: $blue-green transparent $blue-green transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
}

.lds-dual-ring.white:after {
  border: 2px solid $white !important;
  border-color: $white transparent $white transparent !important;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.form-control:disabled {
  background-color: $onyx !important;
  opacity: 1;
  cursor: not-allowed;
}

.text-transform-initial {
  text-transform: initial;
}

button:focus {
  outline: none;
}

.toast-container {
  width: 100% !important;
  left: 0 !important;
  margin: 0 !important;
  top: -5px !important;

  .custom-toast {
    text-align: center;
    color: $font-color;

    &.Toastify__toast--error {
      background-color: $error-text;
    }

    button {
      display: none;
    }
  }
}

.form-group {
  padding-right: 25px;
  padding-left: 25px;
}

.custom-multi-select {
  .css-1hwfws3:focus {
    outline: none; // input outline
  }

  .css-hjm3sl:hover,
  .css-hjm3sl:focus {
    outline: none; // input outline
    border-color: transparent !important;
  }

  // dropdown selector
  .css-15k3avv {
    background-color: $primary;
  }

  // search input
  .css-1g6gooi {
    color: $font-color;
  }

  .css-1lxd980 {
    box-shadow: none !important;
    &:hover {
      border-color: none;
    }
  }

  .css-1r36co3 {
    box-shadow: none !important;
  }

  .css-1quhzxg {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding-right: 6px;
  }

  .css-go1snr {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .css-d8oujb {
    display: none;
  }

  .css-1ep9fjw {
    margin-right: 9px;
    cursor: pointer;

    svg {
      display: none;
    }
  }

  .css .css-19bqh2r,
  .css-1uq0kb5 {
    margin-right: 9px;

    svg {
      display: none;
    }
  }

  &.css-1sontr1 {
    pointer-events: unset;

    .css-8eb2qk {
      cursor: not-allowed;
    }

    .css-ln5n5c {
      display: none;
    }
  }
}

.label-mb {
  margin-bottom: 10px !important;
}

.btn-modal,
.btn-generic {
  min-width: 120px;
  min-height: 42px;
}

.popover-body {
  font-family: "Poppins";
  font-size: 10px;
  color: rgba(102, 102, 102, 1);
  text-align: left;
  line-height: 16px;
  max-width: 200px;
  padding: 12px;
}

.popover-btn {
  padding: 0;
  line-height: 0;
  margin-left: 5px;
  margin-top: -2px;
}

.disabled {
  cursor: not-allowed;
}

.pointer-events-none {
  pointer-events: none;
}

.cursor-progress {
  cursor: progress;
}

.search-icon {
  background-color: $blue-green;
  border-color: $blue-green;
}

/* Placeholder color for IE: need to fix, not working */
::-ms-input-placeholder {
  color: hsl(0, 0%, 50%) !important;
  color: $placeholder !important;
}

::placeholder {
  color: hsl(0, 0%, 50%);
  color: $placeholder;
}
.heading-text-div {
  padding-top: 16px;
  margin-bottom: 16px !important;
}
.w-100 {
  width: 100%;
}

