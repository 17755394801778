@import 'variables';
// .react-bootstrap-table tbody {
//   display: block;
//    max-height: 500px;
//    /*overflow-y: scroll;*/
//    overflow: auto;
// }

// /* Make tr's in both thead & tbody into tables w/ fixed width layout */
// .react-bootstrap-table  thead > tr,
// .react-bootstrap-table  tbody > tr {
//     display: table;
//     width: 100%;
//     table-layout: fixed;
// }

.react-bootstrap-table {
  width: 100%;
  overflow: auto;
  display: table;
  .table {
    border-collapse: separate;
    // border-collapse: collapse;
    border-spacing: 0 6px;
    // min-width: 658px;

    // overflow-x: auto;
    thead th {
      vertical-align: middle;
    }

    th,
    td {
      border: none;
      background-color: $primary;
      // text-align: center;
    }

    th {
      text-transform: uppercase;
      font-weight: 500;
      background-color: #333333;
      font-size: 12px;
      height: 50px;
      // background-image: linear-gradient(-180deg, #004355 0%, #28444B 75%, #364D49 100%);
      background-image: linear-gradient(-180deg, #5097ab 0%, #28444B 75%, #364D49 100%);
      &:first-child {
        border-radius: 4px 0 0 4px;
      }

      &:last-child {
        border-radius: 0 4px 4px 0;
      }
    }

    td {
      text-transform: capitalize;
      font-size: 13px;
      // color: $font-color;
    }
    tbody:before {
      content: "-";
      display: block;
      line-height: 0em;
      color: transparent;
      margin: -4px;
  }
    .custom-row-class {
      padding: 10px;
      margin-bottom: 10px;
      padding-bottom: 15px;
      font-size: 13px;
      letter-spacing: 0.04em;
      color: $font-color;
      border-radius: 4px 0 0 4px;
      td:first-child {
        border-radius: 4px 0 0 4px;
      }

      &.upcoming {
        color: $font-color;

        td:first-child {
          // border-left: solid 4px $blue-green;
          border-radius: 4px 0 0 4px;
        }
      }

      &.actions-clicked td {
        border: solid #ccc 1px;
        padding: 5px 7px;
      }

      &.selection-row {
        td {
          border-bottom: solid 10px $blue-green;
        }
      }
    }

  }

  .react-bs-table-no-data {
    text-align: center;
    font-size: 15px;
  }
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .react-bootstrap-table table {
    border-collapse: separate !important;
  }
  .last-row-sticky td:last-child {
    // position: absolute;
    // // right: 0;
    // z-index: 1;
    // box-shadow: -3px 0 4px 0 $box-shadow;
    // width: 45px;
  }
}

.last-row-sticky td:last-child {
  position: sticky;
  right: 0;
  z-index: 1;
  box-shadow: -3px 0 4px 0 $box-shadow;
  width: 45px;
}

// Loading and spinner
.load-more {
  color: $blue-green;
  background-color: $dark-jungle-green;
  min-width: 150px;
  border-radius: 2px;

  @media screen and (max-width: 600px) {
    width: 100%
  }


}

.single-column-list {
  .react-bootstrap-table {
    table {
      td {
        font-size: 15px;
        color: $font-color;
      }

      thead {
        display: none;
      }
    }
  }
}

@media (min-width: 768px) {
  .single-column-list {
    .react-bootstrap-table {
      table {
        border-spacing: 0;

        tbody {
          // display: flex;
          // flex-wrap: wrap;
          display: block;

          .custom-row-class {
            float: left;
            display: flex;
            position: relative;
            padding: 0;
            margin: 30px 30px 0 0;
            // margin: 10px;
            // &:first-child{
            //   margin-left: 0;
            // }
            &.disable {
              td:last-child {
                opacity: 0;
                &:hover {
                  opacity: 0 !important;
                }
              }
            }
            &.upcoming {
              td {
                &:first-child {
                  position: relative;
                  overflow: hidden;
                  border: none;
                  background-color: $black;
                  border: 2px solid $blue-green;
                  // &:before {
                  //   content: "";
                  //   width: 0;
                  //   height: 0;
                  //   border-left: 20px solid transparent;
                  //   border-right: 20px solid transparent;
                  //   border-bottom: 20px solid rgba(2, 133, 157, 1);
                  //   border-radius: 3px;
                  //   left: -12px;
                  //   top: -3px;
                  //   position: absolute;
                  //   transform: rotate(-45deg);
                  //   box-shadow: 0 0 10px 0 rgba(39, 164, 195, 0.6);
                  // }
                }
              }
            }

            td {
              &:first-child {
                height: 102px;
                width: 204px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $rich-black
              }

              &:last-child {
                position: absolute;
                background-color: $black;
                border: 2px solid $upsdell-red;
                border-radius: 3px;
                box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.8);
                width: 100%;
                height: 100%;
                top: 0;
                right: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                opacity: 0;
                transition: all ease-out 0.1s;

                &:hover {
                  opacity: 1;
                  
                }
                
                span {
                  &.delete {
                    position: relative;
                    // padding-bottom: 25px;
                    width: 18px;
                    text-align: center;

                    // &:after {
                    //   content: "Delete";
                    //   position: absolute;
                    //   bottom: 0;
                    //   right: 0;
                    //   left: 0;
                    //   color: rgba(0, 195, 231, 1);
                    // }
                  }
                }
              }
            }
            
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .single-column-list {
    .react-bootstrap-table {
      .custom-row-class {
        &.upcoming {
          td {
            &:first-child {
              position: relative;
              overflow: hidden;
              border: none;

              &:before {
                content: "";
                width: 0;
                height: 0;
                border-left: 20px solid transparent;
                border-right: 20px solid transparent;
                border-bottom: 20px solid rgba(2, 133, 157, 1);
                border-radius: 3px;
                left: -14px;
                top: -4px;
                position: absolute;
                transform: rotate(-45deg);
                box-shadow: 0 0 10px 0 rgba(39, 164, 195, 0.6);
              }
            }
          }
        }

        td {
          height: 87px;
          text-align: center;
          vertical-align: middle;
          color: white;

          &:last-child {
            width: 70px;
          }
        }
      }
    }
  }
}