@import './../../styles/_variables';

.add-new-item-form {
  .add-item-btn {
    min-width: 150px;
  }
  .form-group {
    margin-right: 1.25rem !important;
  }
  @media screen and (max-width: 767px) {
    .form-group{
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
    .add-item-btn {
      width: 100%;
    }
  }


  .css-1ep9fjw {

    &:first-child {
      background: url("./../../assets/icons/close.svg") no-repeat;
      background-position: center;
    }

    &:last-child {
      background: url("./../../assets/icons/Sort_desc.svg") no-repeat;
      background-position: center;
      position: absolute;
      right: 0px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .css .css-19bqh2r,
  .css-1uq0kb5 {

    &:last-child {
      background: url("./../../assets/icons/Sort_asc.svg") no-repeat;
      background-position: center;
    }

    &:first-child {
      background: url("./../../assets/icons/close.svg") no-repeat;
      background-position: center;
    }
  }

  .css-xp4uvy {
    color: $font-color !important;
    display: contents;
    padding-top: 2%;
  }
}