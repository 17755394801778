@import "./../../../../node_modules/react-bootstrap-toggle/dist/bootstrap2-toggle.css";
@import "./../../../styles/variables";

.communication-toggle {
  @media screen and (max-width: 767px) {
    padding-left: 5px;
    padding-right: 10px;
  }

  /*#region Toggle*/
  .switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 10px;
    top: 8px;
    margin-left: 0.3rem;
    margin-right: 14px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000000;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  .slider.disable:before {
    background-color: $secondary-dev !important;
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: -8px;
    top: -6px;
    background-color: #9f1923;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    .disable {
      background-color: $secondary-dev !important;
    }
  }

  input:checked + .slider {
    background-color: $skobeloff;
  }
  input:checked + .slider.disable {
    background-color: $secondary-dev !important;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
    background-color: rgba(2, 133, 157, 1);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  /*#endregion */
  .toggle {
    display: inline-block !important;
    width: 51px !important;
    height: 27px !important;
    margin-right: 9px;
  }
  .toggle-handle {
    background-color: white;
  }
  .toggle-group {
    span h2 {
      font-size: 1rem !important;
    }
    .toggle-on,
    .toggle-off {
      padding-top: 10px;
    }
  }
  .communication-check {
    padding-top: 23px;
  }
}

.popover-body {
  font-family: "Poppins";
  font-size: 10px;
  color: #666666;
  text-align: left;
  line-height: 16px;
  max-width: 200px;
  padding: 12px;
}
.warning-message {
  font-size: 16px;
  color: red;
  padding-right: 10px;
  text-align: center;
  width: 100%;
}
.popover {
  background-color: #fff;
  margin-right: 0;
  width: 170px;
}
.comm-switch {
  width: 70px;
}
.communication-possible {
  margin-top: -2px;
}