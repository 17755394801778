.checkbox {
  &.disable {
    cursor: not-allowed;
  }
  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"] + label {
    background: url("./../../../assets/icons/Checkbox_unchecked.svg") no-repeat;
    background-size: 100%;
    height: 14px;
    width: 14px;
    display: inline-block;
    padding: 0 0 0 0;
  }

  input[type="checkbox"]:checked + label {
    background: url("./../../../assets/icons/Checkbox.svg") no-repeat;
    background-size: 100%;
    height: 14px;
    width: 14px;
    display: inline-block;
    padding: 0 0 0 0;
  }
}
