@import "./../../styles/modal-styles.scss";
.warning.error {
  .modal-content {
    width: 90% !important;
  }
}
.warning-popup-modal {
  &.error {
    .modal-content {
      width: 90% !important;
    }
  }
  .modal-content {
    height: 150px;
    display: block;
  }
  .modal-body {
    padding: 1rem;
    height: 100%;
    .message-div {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      text-align: center;
      color: red;
    }
  }
}
